.normal-text-input, .short-text-input {
    outline: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 15px;
}

.normal-text-input:hover, .short-text-input:hover {
    box-shadow: #e5e5e5 0px 2px 8px 0px;
}

.normal-text-input {
    padding: 12px 10px;
    width: 250px;
}

.short-text-input {
    padding: 10px;
    width: 100px;
}

.radio-button {
    width: 260px;
    padding: 12px 5px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    box-sizing: content-box;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
}

.radio-button > label {
    cursor: pointer;
}

.radio-button:hover {
    background-color: #f5f5f5;
}

.radio-button-label {
    margin-left: 8px;
}