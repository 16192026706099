.login-cover {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.login-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
}