.table {
    border-collapse: collapse;
    border-radius: 4px;
    overflow-x: auto;
    max-width: 100%;
    display: block;
}

.table > thead > tr > th {
    padding: 10px 15px;
    font-weight: normal;
    text-align: left;
}

.table > tbody > tr > td {
    padding: 10px 15px;
    /* text-align: center; */
}

.table > tbody > tr:nth-child(odd) {
    background-color: #f5f5f5;
}

.table > tbody > tr:nth-child(even) {
    background-color: #fff;
}
