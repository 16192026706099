.headers {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 35px;
    width: 100%;
}

.filter-table > .first-label {
    margin-right: 10px;
}

.filter-table > .middle-label {
    margin-right: 10px;
    margin-left: 10px;
}
