.main-button, .main-button-outline {
    padding: 10px 15px;
    min-width: 100px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
}

.main-button {
    border: none;
}

.main-button-outline {
    border: 1px solid #ddd;
    margin: 0 10px;
}

.pain-button {
    border: none;
    background-color: transparent;
    padding: 1px 4px;
    cursor: pointer;
}

.pain-button:hover {
    opacity: .5;
}

