.form-row, .form-last-row {
    padding: 10px 0;
    display: flex;
}

.form-last-row {
    justify-content: flex-end;
}

.form-label-container {
    width: 100px;
    display: inline-block;
    text-align: end;
    margin-right: 12px;
    padding: 12px 0;
}

.invalid-field {
    text-align: center;
    font-style: italic;
    color: red;
    margin-bottom: 20px;
}

