.pagination {
    margin-top: 15px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
}

.pagination-button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2px;
    width: 30px;
    height: 30px;
    font-size: 16px;
    border-radius: 12px;
    cursor: pointer;
}

.pagination > .unselected {
    background-color: transparent;
    color: #555;
    /* border: 1px solid #ddd; */
}

.pagination > .unselected:hover {
    background-color: #eee;
}