.sidebar_container {
    background-color: #313037;
    height: 100%;
    width: 225px;
    position: fixed;
    left: 0;
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar_item_button, .sidebar_item_button_last {
    padding: 15px 20px;
    width: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sidebar_item_button:hover, .sidebar_item_button_last:hover {
    background-color: #222;
}

.sidebar_icon, .sidebar_icon_selected {
    margin-right: 15px;
    font-size: 18px;
    color: #ddd;
}

.sidebar_label, .sidebar_label_selected {
    cursor: pointer;
    font-size: 16px;
    color: #ddd;
}

.sidebar_icon_selected, .sidebar_label_selected {
    color: #7fc49b;
}

.sidebar_item_button_last {
    margin-bottom: 20px;
}

.sidebar_header {
    padding: 10px 20px;
}

.sidebar_title {
    color: #fff;
    font-weight: normal;
}

.sidebar_hr {
    border-bottom: 1px solid #dddddd44;
    margin: 20px 0;
}

