.heading-container {
    display: flex;
    align-items: center;
}

.heading-container > h1 {
    font-size: 28px;
    margin: 0;
    margin-bottom: 2px;
}

.heading-container > .add-button {
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    width: 32px;
    height: 32px;
    margin-left: 15px;
}

.heading-container > .add-icon {
    font-size: 14px;
}