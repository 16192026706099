.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 20px;
    margin-left: 225px;
}

.page-container > .cover {
    max-width: 100%;
}
