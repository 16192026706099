.primary-color, .primary-color-no-hover {
    background-color: #0077be;
    color: #fff;
}

.primary-color:hover {
    background-color: #0067a4;
}

.primary-outline-color {
    background-color: #fff;
    color: #0067a4;
}

.primary-outline-color:hover {
    background-color: #f5f5f5;
}

.primary-text-color {
    color: #0067a4;
}

.primary-border-color {
    border: 1px solid #0067a4
}
