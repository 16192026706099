.title-container {
    text-align: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 35px;
}

.title {
    padding-bottom: 10px;
    margin: 0;
    font-size: 28px;
}